//Large Screen
@mixin xl {
  @media only screen and (min-width: 1600px) {
    @content;
  }
}

//desktop Normal screen
@mixin lg {
  @media only screen and (min-width: 1300px) and (max-width: 1599px) {
    @content;
  }
}

// Laptop Screen
@mixin md {
  @media only screen and (min-width: 1024px) and (max-width: 1299px) {
    @content;
  }
}

//Tab Screen
@mixin tab {
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    @content;
  }
}

//Mobile Screen Horizantal
@mixin mob-hor {
  @media only screen and (min-width: 568px) and (max-width: 767px) {
    @content;
  }
}

//Mobile Screen
@mixin mob-ver {
  @media only screen and (min-width: 320px) and (max-width: 545px) {
    @content;
  }
}

// minimun size

@mixin xl-min {
  @media only screen and (min-width: 1600px) {
    @content;
  }
}

@mixin lg-min {
  @media only screen and (min-width: 1300px) {
    @content;
  }
}

@mixin md-min {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}

@mixin tab-min {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin mob-hor-min {
  @media only screen and (min-width: 568px) {
    @content;
  }
}

@mixin mob-ver-min {
  @media only screen and (min-width: 320px) {
    @content;
  }
}

// maximum size

@mixin xl-max {
  @media only screen and (max-width: 1599px) {
    @content;
  }
}

@mixin lg-max {
  @media only screen and (max-width: 1299px) {
    @content;
  }
}

@mixin md-max {
  @media only screen and (max-width: 1023px) {
    @content;
  }
}

@mixin tab-max {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin mob-hor-max {
  @media only screen and (max-width: 567px) {
    @content;
  }
}

@mixin mob-ver-max {
  @media only screen and (max-width: 320px) {
    @content;
  }
}
