@import './resolution.scss';

.dashboard-container {
    .err-notification-cont {
        height: 368px;
    }
}

.chart-cont1 {
    .col-md-6:first-child {
        margin-bottom: 0px !important;
    }
}

@media only screen and (max-width: 1097.14px) {
    // .ant-radio-group{
    //     width: 180px;
    //     .ant-radio-button-wrapper{
    //         height: 26px;
    //         span{
    //             font-size: 10px !important;
    //         }
    //     }
    // }
    .dashboard-container {
        .col-md-9 {
            width: 72%;

            .dash-cards {
                display: flex;
                flex-direction: row;

                .dashboard-cards.col {
                    h4.text-white {
                        margin-bottom: 0px !important;
                    }

                    .col-auto.p-2.rounded-circle.ms-3 {
                        margin-left: 0px !important;
                    }

                    span.text-white {
                        font-size: 9.5px !important;
                    }
                }
            }
        }

        .fs-15 {
            font-size: 10px !important;
        }

        .fs-14 {
            font-size: 9px !important;
        }

        .col-md-3 {
            width: 28%;

            .err-notification-cont {
                height: 350px;
            }

            .note-cont {
                height: 450px;

                .ant-avatar {
                    width: 20px !important;
                    height: 20px !important;

                    .ant-avatar-string {
                        font-size: 10px !important;
                    }
                }

                a.text-decoration-underline {
                    font-size: 9px !important;
                }
            }
        }

        .chart-cont1 {
            .col-md-6:first-child {
                margin-bottom: 0px !important;
            }
        }
    }

    .ant-drawer {
        .ant-drawer-header {
            .ant-drawer-close {
                span {
                    font-size: 12px !important;
                }
            }

            .ant-drawer-title {
                text-align: left;

                h5 {
                    margin-left: 0px !important;
                    font-size: 13px !important;
                }
            }
        }

        .ant-drawer-body {
            .ant-avatar {
                width: 20px !important;
                height: 20px !important;

                .ant-avatar-string {
                    font-size: 10px !important;
                }
            }

            .text-gray {
                font-size: 9px !important;
            }

            a.text-decoration-underline {
                font-size: 9px !important;
            }
        }
    }

    h5 {
        margin-left: 0px !important;
    }
}

@media only screen and (max-width: 1280px) {
    .dashboard-container {
        .col-md-9 {
            .dash-cards {
                display: flex;
                flex-direction: row;

                .dashboard-cards.col {
                    h4.text-white {
                        margin-bottom: 0px !important;
                    }

                    span.fw-bold {
                        font-size: 10px !important;
                    }

                    .col-auto.p-2.rounded-circle.ms-3 {
                        margin-left: 0px !important;
                    }
                }
            }
        }

        .col-md-3 {
            .err-notification-cont {
                height: 353px;
            }

            .note-cont {
                height: 450px;

                .ant-avatar {
                    width: 30px !important;
                    height: 30px !important;

                    .ant-avatar-string {
                        font-size: 12px !important;
                    }
                }

                h6 {
                    font-size: 10px !important;
                }

                .text-gray {
                    font-size: 10px !important;
                }

                a.text-decoration-underline {
                    font-size: 9px !important;
                }
            }
        }
    }

    .ant-drawer {
        .ant-drawer-header {
            .ant-drawer-close {
                span {
                    font-size: 12px !important;
                }
            }

            .ant-drawer-title {
                text-align: left;

                h5 {
                    margin-left: 0px !important;
                    font-size: 14px !important;
                }
            }
        }

        .ant-drawer-body {
            h6 {
                font-size: 10px !important;
            }

            .ant-avatar {
                width: 30px !important;
                height: 30px !important;

                .ant-avatar-string {
                    font-size: 12px !important;
                }
            }

            .text-gray {
                font-size: 10px !important;
            }

            a.text-decoration-underline {
                font-size: 10px !important;
            }
        }
    }

}

// 125%
@include lg {
    .dashboard-container {
        .err-notification-cont {
            height: 356px;
        }

        .note-cont {
            height: 450px;

            .ant-avatar {
                width: 35px !important;
                height: 35px !important;

                .ant-avatar-string {
                    font-size: 14px !important;
                }
            }
        }
    }
}

@include tab {
    .dashboard-container {
        .col-md-3 {
            .err-notification-cont {
                margin-top: 10px;
            }
        }
    }
}

@include mob-ver {
    .dashboard-container {
        .chart-cont1 {
            .col-md-6:first-child {
                margin-bottom: 15px !important;
            }
        }

        .rel-req-chart {
            .apexcharts-canvas {
                .apexcharts-legend {
                    width: 150px !important;
                }
            }
        }

        .col-md-3 {
            .err-notification-cont {
                margin-top: 10px;
            }
        }
    }
}
@media only screen and (min-width:534px) and (max-width: 586px){
    .dashboard-container{
        .dashboard-cards {
            flex: 50% !important;
        }
        .col-md-9,.col-md-3{
            width: 100% !important;
        }
    }
}
@media only screen and (min-width:300px) and (max-width: 587px) {
    .dashboard-container{
        .dashboard-cards {
            flex: 50% !important;
        }
    }
    
}